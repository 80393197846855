<template>
  <!--begin::Card-->
  <div class="card">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            data-kt-subscription-table-filter="search"
            class="form-control form-control-solid w-250px ps-14"
            placeholder="Search User"
            v-model="searchUsers"
          />
        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->

      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Toolbar-->
        <div
          class="d-flex justify-content-end"
          data-kt-subscription-table-toolbar="base"
        >
          <!--begin::Export-->
          <button
            type="button"
            class="btn btn-light-primary me-3"
            data-bs-toggle="modal"
            data-bs-target="#kt_subscriptions_export_modal"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
            Export
          </button>
          <!--end::Export-->

          <!--begin::Add subscription-->
          <router-link to="/customers/add-customer" class="btn btn-primary">
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr075.svg" />
            </span>
            Add User
          </router-link>
          <!--end::Add subscription-->
        </div>
        <!--end::Toolbar-->

        <!--begin::Group actions-->
        <div
          class="d-flex justify-content-end align-items-center d-none"
          data-kt-subscription-table-toolbar="selected"
        >
          <div class="fw-bolder me-5">
            <span
              class="me-2"
              data-kt-subscription-table-select="selected_count"
            ></span>
            Selected
          </div>

          <button
            type="button"
            class="btn btn-danger"
            data-kt-subscription-table-select="delete_selected"
          >
            Delete Selected
          </button>
        </div>
        <!--end::Group actions-->
      </div>
      <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-0">
      <KTDatatable
        :table-data="data"
        :table-header="table.columns"
        :current-page="table.currentPage"
        :total="total"
        :rows-per-page="table.perPage"
        :sort-lable="id"
        :loading="loading"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
        @sort="handleSortChange"
      >
        <template v-slot:cell-checkbox="{ row: user }">
          <div
            class="form-check form-check-sm form-check-custom form-check-solid"
          >
            <input class="form-check-input" type="checkbox" :value="user.id" />
          </div>
        </template>
        <template v-slot:cell-name="{ row: user }">
          {{ user.name }}
          <!--          <router-link-->
          <!--            to="/users/view-subscription"-->
          <!--            href=""-->
          <!--            class="text-gray-800 text-hover-primary mb-1"-->
          <!--          >-->
          <!--            {{ user.user.name }}-->
          <!--          </router-link>-->
        </template>
        <template v-slot:cell-email="{ row: user }">
          <div data-bs-toggle="tooltip" :title="user.email">
            {{ user.email ? user.email.slice(0, 25) : "" }}
          </div>
        </template>
        <template v-slot:cell-device_type="{ row: user }">
          <div class="badge badge-light">
            {{ user.device_type }}
          </div>
        </template>
        <template v-slot:cell-created_at="{ row: user }">
          {{ new Date(user.created_at).toLocaleString() }}
        </template>

        <template v-slot:cell-subscription_status="{ row: user }">
          <a href="#" class="text-gray-600 text-hover-primary mb-1">
            <div
              :class="`badge badge-light-${
                user.subscription
                  ? user.subscription.is_active
                    ? 'success'
                    : 'danger'
                  : 'danger'
              }`"
            >
              <span>{{
                user.subscription
                  ? user.subscription.is_active
                    ? "Active"
                    : "InActive"
                  : "InActive"
              }}</span>
            </div>
          </a>
        </template>
        <template v-slot:cell-subscription_amount="{ row: user }">
          <div class="badge badge-light">
            {{ user.subscription ? user.subscription.amount : 0 }}
          </div>
        </template>
        <template v-slot:cell-actions="{ row: user }">
          <a
            href="#"
            class="btn btn-sm btn-light btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
            >Actions
            <span class="svg-icon svg-icon-5 m-0">
              <inline-svg src="media/icons/duotune/arrows/arr072.svg" />
            </span>
          </a>
          <!--begin::Menu-->
          <div
            class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <!--begin::Menu item-->
            <div class="menu-item px-3">
              <router-link
                to="/apps/customers/customer-details"
                class="menu-link px-3"
                >View
              </router-link>
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div class="menu-item px-3">
              <a @click="deleteCustomer(user.id)" class="menu-link px-3"
                >Delete</a
              >
            </div>
            <!--end::Menu item-->
          </div>
          <!--end::Menu-->
        </template>
      </KTDatatable>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
// import NewCouponModal from "../components/modals/forms/NewCouponModal.vue";
import ApiService from "../core/services/ApiService.ts";
import Swal from "sweetalert2/dist/sweetalert2.js";

// import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";

import RemoteTable from "@/components/RemoteTable.vue";
import useDebounceRef from "../composables/useDebounceRef";
import {MenuComponent} from "@/assets/ts/components";
export default {
  name: "Users",
  components: { KTDatatable },
  updateCouponMode: false,
  setup() {
    let user = [];
    const total = ref(0);
    const table = reactive({
      height: 411,
      data: [],
      columns: [
        { key: "checkbox", sortable: true, sortingField: "id" },
        {
          key: "name",
          name: "Name",
          sortable: false,
        },
        {
          key: "email",
          name: "Email",
          sortable: false,
        },
        {
          key: "device_type",
          name: "Device",
          sortable: true,
        },
        {
          key: "created_at",
          name: "Register Date",
          sortable: true,
        },
        {
          key: "subscription_status",
          name: "Subscription",
          sortable: false,
        },
        {
          key: "subscription_amount",
          name: "Payment",
          sortable: false,
        },
        {
          key: "actions",
          name: "Actions",
          sortable: false,
        },
      ],
      remote: {
        url: "admin/users",
        method: "GET",
      },
      pageSizes: [10, 30, 50, 100],
      currentPage: 1,
      perPage: 10,
      sort: { columnName: "id", order: "desc" },
    });
    const data = ref(user);
    const loading = ref(false);
    const loadUsers = () => {
      loading.value = true;
      ApiService.get(table.remote.url, null, {
        params: {
          page: table.currentPage,
          perPage: table.perPage,
          search: searchUsers.value,
          sort: table.sort,
        },
      })
        .then(({ data: dataC }) => {
          console.log("datadatadatadatadatadatadatadata");
          console.log(dataC.data);
          data.value = dataC.data;
          table.data = dataC.data;
          total.value = dataC.meta.total;
          console.log("total");
          console.log(total);
          console.log("total");
        })
        .catch((err) => {
          console.log("errerrerrerrerrerr");
          console.log(err);
          console.log("errerrerrerrerrerr");
        })
        .finally(() => {
          loading.value = false;
          MenuComponent.reinitialization();
        });
    };

    let searchUsers = useDebounceRef("", 400);

    watch(searchUsers, (newQuery) => {
      loadUsers();
    });
    onMounted(() => {
      setCurrentPageBreadcrumbs("User List", ["Apps", "Users"]);
      // this.getPlans();
      loadUsers();
    });
    return { data, table, total, loading, loadUsers, searchUsers };
  },
  // mounted() {
  //   this.getPlans();
  //   this.loadUsers();
  // },
  data: function () {
    return {
      selectedCoupon: {},
      currentCoupon: {},
      plans: [],
    };
  },
  methods: {
    getPlans() {
      ApiService.get("admin/plans", "", {
        params: {
          all: true,
        },
      }).then(({ data }) => {
        this.plans = data;
      });
    },

    handleSizeChange(perPage) {
      this.table.perPage = perPage;
      this.loadUsers();
    },
    handleSortChange(sort) {
      console.log(sort);
      this.table.sort = sort;
      this.loadUsers();
    },
    handleCurrentChange(page) {
      this.table.currentPage = page;
      this.loadUsers();
      console.log("page");
      console.log(page);
      console.log("page");
    },
    deleteCoupon(coupon) {
      ApiService.delete(`admin/coupons/${coupon.id}`).then(({ data }) => {
        if (data.success) {
          Swal.fire({
            text: data.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            timer: 2000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          this.loadUsers();
        } else {
          Swal.fire({
            text: data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
    couponSaved(coupon) {
      if (!this.updateCouponMode) {
        this.table.data.push(coupon);
      } else {
        Object.assign(this.currentCoupon, coupon);
      }
    },
    getPercentage(item) {
      return ((100 * item.uses) / item.max_uses).toFixed(2);
    },
    getColor(item) {
      const percentage = this.getPercentage(item);
      if (percentage <= 30) return "primary";
      if (percentage <= 60) return "success";
      if (percentage <= 90) return "warning";
      return "danger";
    },
    editCouponClicked(coupon) {
      this.updateCouponMode = true;
      this.selectedCoupon = { ...coupon };
      this.currentCoupon = coupon;
    },
  },
};
</script>
